.session-box-shadow {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25),
    0px 0.16px 9.54074px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: solid;
  border-width: 0.5px;
  border-radius: 10px;
  border-color: #fff;
  width: 480px;
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-container {
  overflow-y: scroll;
  width: calc(100% - 200px);
}

.upload-file-background {
  box-sizing: border-box;
  width: 395px;
  height: 380px;
  background: #f1fbff;
  border: 2px dashed #047eb1;
  border-radius: 4.77037px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploading-box-shadow {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25),
    0px 0.16px 9.54074px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: solid;
  border-width: 0.5px;
  border-radius: 10px;
  border-color: #fff;
  width: 645px;
  height: 590px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-styling {
  grid-row-gap: 1px;
  grid-column-gap: 9px;
}

.add-session-btn {
  background: #047eb1;
  width: 180px;
  padding: 12px;
  height: 50px;
  border-radius: 75px;
  font-weight: bold;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
}

.add-session-btn:hover {
  background: #047eb1;
  opacity: 50%;
  width: 180px;
  padding: 12px;
  height: 50px;
  border-radius: 75px;
  font-weight: bold;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
}

.card-style {
  font-family: "Plus Jakarta Sans";
  font-size: 18px;
  font-weight: 700;
}

.add-file-button {
  background: #047eb1;
  width: 80%;
  padding: 12px;
  height: 50px;
  border-radius: 75px;
  font-weight: bold;
}

.add-file-button:hover {
  background: #047eb1;
  opacity: 50%;
  width: 80%;
  padding: 12px;
  height: 50px;
  border-radius: 75px;
  font-weight: bold;
}
