.box.shadow5:before {
  content: "";
  position: absolute;
  bottom: -15%;
  left: 5%;
  width: 90%;
  height: 90%;
  background: rgba(0, 0, 0, 0.3);
  z-index: -1;
  filter: blur(10px);
}

/* .home-container {
  @media (max-width: 768px) {
    flex-direction: column;
  }
} */

/* @media only screen and (max-width: 820px) {
  .home-container {
    flex-direction: column;
  }
} */
