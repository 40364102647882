.main-container {
  overflow-y: scroll;
  width: calc(100% - 200px);
}
.uploading-box-shadow {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25),
    0px 0.16px 9.54074px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: solid;
  border-width: 0.5px;
  border-radius: 10px;
  border-color: #fff;
  width: 485px;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-styling {
  grid-row-gap: 1px;
  grid-column-gap: 9px;
}

.add-upload-file {
  background: #047eb1;
  width: 80%;
  padding: 12px;
  height: 50px;
  border-radius: 75px;
  font-weight: bold;
}

.add-upload-file:hover {
  background: #047eb1;
  opacity: 50%;
  width: 80%;
  padding: 12px;
  height: 50px;
  border-radius: 75px;
  font-weight: bold;
}
