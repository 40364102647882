.session-boxes-shadow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  /* width: 220.2px;
  height: 191.48px; */
  width: 13rem;
  height: 11rem;
  background: #fdfdfd;
  border: 0.478688px solid #005578;
  box-shadow: 0px 1.91475px 9.57376px rgba(162, 162, 162, 0.5);
  border-radius: 9.57376px;
  padding: 20px;
  cursor: pointer;
}

span {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  color: #005578;
}

h1 {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 35.2721px;
  color: #005578;
  margin-top: 40px;
}

.all {
  background: #005578;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  font-family: "Avenir";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.card-subtitle {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  color: #464646;
  margin-top: -20px;
}

.circle {
  background: #ffffff;
  border: 0.5px solid #979797;
  border-radius: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100px;
  height: 100px;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle:hover {
  background-color: #047db1bc;
}

.cirle-title {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #8c8c8c;
}

.small-circle {
  width: 35px;
  height: 35px;
  border-radius: 17.5px;
  background: #047db1bc;
  margin-top: -20px;
  margin-right: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Avenir";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  color: #ffffff;
}

.grid-container {
  display: grid;
  grid-template-columns: 13rem 13rem 13rem 13rem;
  grid-gap: 2rem;
}
