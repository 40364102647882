@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Plus Jakarta Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    "Plus Jakarta Sans", monospace;
}

p {
  margin-bottom: 0;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.page {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: margin-left 1s;
}

.page-with-navbar {
  margin-left: 15%;
}

@media only screen and (max-width: 768px) {
  .page-with-navbar {
    width: 100%;
    margin-left: 0;
  }
}
