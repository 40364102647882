.user-table {
  padding: 20px;
  font-family: "Plus Jakarta Sans";
}

.ant-divider-horizontal.ant-divider-with-text {
  border-top-color: rgba(0, 82, 128, 0.25);
  font-family: "Plus Jakarta Sans";
}

.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left
  .ant-divider-inner-text {
  font-size: 24px;
  font-weight: 700;
  font-family: "Plus Jakarta Sans";
}

.table-container {
  background: #ffffff;
  border-radius: 8px;
  padding: 1px;
  font-family: "Plus Jakarta Sans";
  width: calc(100% - 50px);
}

.table-column-text {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #047eb1;
  text-align: "center";
  justify-content: "center";
  align-items: "center";
}

.searchbar-and-button-container {
  margin-bottom: 20px;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Plus Jakarta Sans";
}

.table-input {
  font-size: 12px;
  height: 50px;
  background: #cde5ef !important;
  border-radius: 5px;
  color: #047eb1;
  width: 400px;
  font-family: "Plus Jakarta Sans";
  font-weight: 600;
}

@media (max-width: 836px) {
  .table-input {
    width: unset;
    margin-bottom: 10px;
    font-weight: 600;
    font-family: "Plus Jakarta Sans";
  }
}

.table-input ::placeholder {
  color: #047eb1;
  font-size: 12px;
  font-family: "Plus Jakarta Sans";
}

.add-button,
.csv-button {
  background: #ffffff;
  border-radius: 3px;
  height: 40px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  font-style: normal;
  font-size: 15px;
  font-weight: 600;
  color: #047eb1;
  border: 1px solid;
  font-family: "Plus Jakarta Sans";
}

.csv-button {
  background: #fff;
  margin-right: 20px;
  font-family: "Plus Jakarta Sans";
}

.table-input.ant-input-affix-wrapper > input.ant-input {
  background: #cde5ef;
}

.ant-table-thead th.ant-table-column-has-sorters {
  background: #cde5ef;
  color: #047eb1;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.05em;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #cde5ef;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  background: #cde5ef;
}

.changes-button-container {
  width: 100px;
  height: 40px;
}

.changes-button {
  border: 0px;
  background: #ffffff;
  cursor: pointer;
  color: #047eb1;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  font-family: Inter;
  width: 150px;
  text-align: start;
  font-family: "Plus Jakarta Sans";
}

.changes-button:hover {
  background: #cde5ef;
}

.changes-container {
  border: 0px;
  background-color: #cde5ef;
  color: #047eb1;
  cursor: pointer;
}

.ant-table-thead > tr:first-child th:last-child {
  background: #cde5ef;
}

.ant-popover-inner-content {
  padding: unset;
}

.ant-table-column-sorter,
.ant-table-column-sorter:hover {
  color: unset;
  font-weight: 400;
}

.ant-table-thead > tr > th {
  background: #cde5ef;
  color: #047eb1;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-weight: 600;
}

.error-text span {
  color: red;
}

.rejection-modal .ant-form-item-explain-error {
  font-size: 12px;
  margin-top: 0.8rem;
}

.img-preview-btn,
.img-preview-btn:hover,
.img-preview-btn:focus,
.img-preview-btn:after {
  background: unset;
  color: unset;
  border: unset;
  text-decoration: underline !important;
  box-shadow: unset;
  text-shadow: unset;
  transition: unset;
}

.ant-table-tbody > tr > td {
  padding: 8px;
  text-align: center;
  font-family: "Plus Jakarta Sans";
}

.ant-table-cell-ellipsis {
  white-space: unset;
  text-overflow: unset;
  text-align: center;
  width: 140px;
}

.ant-table-cell-ellipsis .ant-table-column-title {
  font-weight: 600;
}

.ant-table-thead th.ant-table-column-has-sorters {
  padding: 8px;
  text-align: center;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  width: 140px;
}

.ant-modal-header {
  padding-top: 30px;
  padding-bottom: 0;
  border-bottom: none;
  border-radius: 15px;
}

.ant-modal-content {
  border-radius: 10px;
}

.buttons-container {
  text-align: center;
}

.submit-btn {
  margin-left: 6px;
  background: #047eb1;
  width: 100px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
}

.submit-btn:hover {
  background: #047eb1;
}

.scores-container {
  margin-top: 20px;
}

.scores-container .score {
  border: 0.5px solid #047eb1;
  border-radius: 3px;
  font-weight: bold;
  padding: 5px 10px;
  margin-left: 10px;
  width: 140px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-family: "Plus Jakarta Sans";
}

.score-modal-img-container {
  margin-top: 20px;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Plus Jakarta Sans";
}

.ant-tag-green {
  color: #198658;
  background: #ceeee1;
  border-color: #198658;
  font-family: "Plus Jakarta Sans";
}

.rejection-modal-heading,
.scores-rejected-text {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  display: block;
  font-family: "Plus Jakarta Sans";
}

.scores-rejected-text {
  margin: 0;
  color: #cf1322;
  font-weight: 400;
}

.ant-image-preview-img {
  width: 300px;
}

.edit-btn,
.reject-btn {
  background: #047eb1;
  color: #fff;
  padding: 10px;
  height: max-content;
  border-radius: 5px;
  font-weight: bold;
  border: none;
  font-family: "Plus Jakarta Sans";
}

.reject-btn {
  background: red;
}

.reject-btn:hover {
  color: #fff;
  background: #da3e3e;
}

.edit-btn:hover {
  background: #047eb1;
  color: #fff;
  font-weight: 600;
  font-family: "Plus Jakarta Sans";
}

.grey-txt span {
  color: #8b8383;
}

.transactionModal {
  width: 50% !important;
}

.ant-descriptions-title {
  display: block;
  text-align: start;
  font-size: 24px;
  font-weight: 600;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background: #fff;
  width: 40%;
  font-size: 16px;
  border-right: 0.5px solid #000000;
  font-family: "Plus Jakarta Sans";
}

.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 0.5px solid #000000;
}

.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  font-family: "Plus Jakarta Sans";
}

.ant-descriptions-bordered .ant-descriptions-view {
  border: 0.5px solid #000000;
}

.transactionModal .ant-modal-close-x svg {
  stroke: black;
  margin-top: 27px;
}

.transactionModalCancelBtn {
  border: 1px solid #5e5e5e;
  border-radius: 3px;
  margin-top: 25px;
  color: #5e5e5e;
  font-size: 14px;
  width: 148px;
  font-family: "Plus Jakarta Sans";
}

.ant-table-filter-dropdown,
.ant-table-filter-dropdown-btns {
  border-radius: 6px;
}

.ant-input-affix-wrapper > input.ant-input,
.ant-input-affix-wrapper {
  background: #ecf5ff;
  border-radius: 6px;
}

.ant-table-filter-dropdown .ant-checkbox-wrapper + span {
  color: #047eb1;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  font-family: "Plus Jakarta Sans";
}

.ant-table-filter-dropdown-search.ant-input {
  color: red;
}

.ant-input-affix-wrapper > input.ant-input,
.ant-input-affix-wrapper ::placeholder {
  color: #047eb1 !important;
  font-size: 12px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans";
}

.ant-table-filter-dropdown-search-input .anticon {
  color: #047eb1;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

.sampletable-checkbox .ant-checkbox-inner {
  border-radius: 50%;
}

.sampletable-checkbox-background .ant-checkbox-checked .ant-checkbox-inner {
  background-color: green;
  border-color: green;
}

.font-style-subheading {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #000000;
}
