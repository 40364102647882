.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70vw;
  color: #005578;
  margin-bottom: 15px;
}

.add-session {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  background: #047eb1;
  border-radius: 100px;
  color: #ffffff;
  text-transform: uppercase;
}

.row {
  display: flex;
  cursor: "pointer";
  width: 100%;
  margin-top: 70px;
}

.card-container {
  max-width: 25%;
  width: 200px;
  height: 140px;
  border-radius: 10px;
  border: solid 0.5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 20px;
  margin-right: 15px;
  cursor: "pointer";
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25),
    0px 0.16px 9.54074px rgba(0, 0, 0, 0.25);
}

.card-1 {
  background-color: #e4f2f8;
  border-color: #005578;
  cursor: "pointer";
}

.card-2 {
  background-color: #faeab9;
  border-color: #ea8315;
  cursor: "pointer";
}

.sub-header {
  margin-top: 50px;
}

.add-upload-file-button {
  background: #047eb1;
  width: 80%;
  padding: 12px;
  height: 50px;
  border-radius: 75px;
  font-weight: bold;
}
.add-upload-file-button {
  background: #00567a;
  width: 80%;
  padding: 12px;
  height: 50px;
  border-radius: 75px;
  font-weight: bold;
}
