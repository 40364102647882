.nav {
  /* position: absolute;
  top: 0;
  left: 0; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 250px;
  max-height: 100vh;
  background-color: #00567a;
  /* padding: 2rem 1rem 1rem 1rem; */
  transition: transform 1s;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.navbar {
  transform: translateX(-100%);
}

.nav-btn {
  position: absolute;
  transform: translateX(38px);
  top: 40px;
  right: 0;
  width: 40px;
  height: 60px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00567a;
  outline: none;
  border: none;
  font-size: 1rem;
  padding: 5px 10px;
  cursor: pointer;
  color: #fff;
}

.logo {
  display: block;
  margin: auto;
  margin-top: 80px;
  margin-right: 60px;
  justify-content: center;
  align-items: center;
  background: transparent;
  margin-left: 30px;
}

.nav-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 10px 1rem;
  padding-left: 30px;
  border-radius: 5px;
  margin-bottom: 5px;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.03em;
  color: #ffffffe7;
}

.nav-link:hover {
  background-color: #eefaff;
  color: #005578;
  border-radius: 0px 25px 25px 0px;
  font-size: 18px;
  width: 100%;
  left: 5px;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: bolder;
}

.nav-link-selected {
  background-color: #eefaff;
  color: #005578;
  border-radius: 0px 25px 25px 0px;
  font-size: 18px;
  width: 100%;
  left: 5px;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: bolder;
}

.nav-link img {
  margin-right: 10px;
}

.nav-top {
  margin-top: 10rem;
}

.mobile-nav {
  background-color: #00567a;
  width: 100%;
  height: 40px;
  display: none;
}

.mobile-nav-btn {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  margin: 0 10px;
}

@media only screen and (max-width: 768px) {
  nav {
    width: 40%;
    padding: 10px;
  }
}

@media only screen and (max-width: 450px) {
  .mobile-nav {
    display: flex;
    align-items: center;
  }
  nav {
    width: 70%;
    top: 38px;
    min-height: calc(100vh - 38px);
  }

  .nav-btn {
    display: none;
  }
}

.log-style {
  font-size: 22px;
  font-family: "Plus Jakarta Sans";
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}

.log-style2 {
  font-family: "Plus Jakarta Sans";
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
  opacity: 0.9;
  padding-left: 55px;
  cursor: pointer;
}

.logout-logo {
  display: block;
  width: 60px;
  height: 60px;
  margin: auto;
  margin-left: 10px;
  margin-bottom: 50px;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.profile-pic {
  width: 50px;
  height: 50px;
  /* border: 1px solid #ffffff; */
  border-radius: 30px;
}

.profile-name {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #ffffff;
}

.profile-email {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  padding-top: -20px;
}

.badge {
  background: #dc3434;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 10.0921px;
  color: #ffffff;
  margin-bottom: -15px;
  margin-right: -5px;
  z-index: 999;
}
